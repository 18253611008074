<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <b-tabs>
        <b-tab title="Registration">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ValidationObserver 
                    v-slot="{ handleSubmit }" 
                    ref="VFormHeader"
                  >
                    <form
                      @submit.prevent="
                        handleSubmit(
                          updateContent(
                            'registration',
                            content.web_registration,
                            'VFormHeader',
                            false,
                            false
                          )
                        )
                      "
                    >
                      <div class="row">
                        <div class="col-12 col-md-8">
                          <div class="form-group">
                            <label>
                              Event Url
                              <span class="text-danger mr5">*</span>
                            </label>
                            <template v-if="editFormId == 'registration'">
                              <b-form-input
                                v-model="content.web_registration.value.event.url"
                                :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                rows="5"
                              ></b-form-input>
                              <VValidate
                                name="Event Url"
                                v-model="content.web_registration.value.event.url"
                                rules="required"
                              />
                            </template>
                            <template v-else>
                              <div>
                                <p>{{ content.web_registration.value.event.url }}</p>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-12 col-md-8">
                          <div class="form-group">
                            <label>
                              Event Open
                              <span class="text-danger mr5">*</span>
                            </label>
                            <template v-if="editFormId == 'registration'">
                              <div>
                                <InputRadio v-model="content.web_registration.value.event.open" name="status" label="Yes" option="1" />
                                <InputRadio v-model="content.web_registration.value.event.open" name="status" label="No" option="0" />
                              </div>
                              <VValidate
                                name="Event Open"
                                v-model="content.web_registration.value.event.open"
                                rules="required"
                              />
                            </template>
                            <template v-else>
                              <div>
                                <p>{{ content.web_registration.value.event.open == '1' ? 'Yes' : 'No' }}</p>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-12 col-md-8">
                          <div class="form-group">
                            <label>
                              Expo Url
                              <span class="text-danger mr5">*</span>
                            </label>
                            <template v-if="editFormId == 'registration'">
                              <b-form-input
                                v-model="content.web_registration.value.expo.url"
                                :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                rows="5"
                              ></b-form-input>
                              <VValidate
                                name="Expo Url"
                                v-model="content.web_registration.value.expo.url"
                                rules="required"
                              />
                            </template>
                            <template v-else>
                              <div>
                                <p>{{ content.web_registration.value.expo.url }}</p>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-12 col-md-8">
                          <div class="form-group">
                            <label>
                              Expo Open
                              <span class="text-danger mr5">*</span>
                            </label>
                            <template v-if="editFormId == 'registration'">
                              <div>
                                <InputRadio v-model="content.web_registration.value.expo.open" name="status" label="Yes" option="1" />
                                <InputRadio v-model="content.web_registration.value.expo.open" name="status" label="No" option="0" />
                              </div>
                              <VValidate
                                name="Expo Open"
                                v-model="content.web_registration.value.expo.open"
                                rules="required"
                              />
                            </template>
                            <template v-else>
                              <div>
                                <p>{{ content.web_registration.value.expo.open == '1' ? 'Yes' : 'No' }}</p>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="moduleRole('Edit')">
                        <div class="col-12">
                          <hr />
                          <div class="text-right">
                            <template v-if="editFormId == 'registration'">
                              <button
                                type="button"
                                @click="editFormId = ''"
                                class="btn btn-rounded btn-light mr-2"
                              >
                                Cancel
                              </button>
                              <button type="submit" class="btn btn-rounded btn-primary">
                                Save
                              </button>
                            </template>
                            <template v-else>
                              <button
                                type="button"
                                @click="editFormId = 'registration'"
                                class="btn btn-rounded btn-primary"
                              >
                                Ubah
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Content">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ValidationObserver 
                    v-slot="{ handleSubmit }" 
                    ref="VFormHeader"
                  >
                    <form
                      @submit.prevent="
                        handleSubmit(
                          updateContent(
                            'header',
                            content.web_settings,
                            'VFormHeader',
                            false,
                            false
                          )
                        )
                      "
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Address
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == 'header'">
                                  <b-form-input
                                    v-model="content.web_settings.value.header.address"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    rows="5"
                                  ></b-form-input>
                                  <VValidate
                                    name="Address"
                                    v-model="content.web_settings.value.header.address"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <div>
                                    <p>{{ content.web_settings.value.header.address }}</p>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Maps URL
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == 'header'">
                                  <b-form-textarea
                                    v-model="content.web_settings.value.header.maps_url"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    rows="5"
                                  ></b-form-textarea>
                                  <VValidate
                                    name="Maps URL"
                                    v-model="content.web_settings.value.header.maps_url"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <div>
                                    <p>{{ content.web_settings.value.header.maps_url }}</p>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Email
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == 'header'">
                                  <b-form-input
                                    v-model="content.web_settings.value.header.email"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    rows="5"
                                  ></b-form-input>
                                  <VValidate
                                    name="Email"
                                    v-model="content.web_settings.value.header.email"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <div>
                                    <p>{{ content.web_settings.value.header.email }}</p>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Instagram
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == 'header'">
                                  <b-form-input
                                    v-model="content.web_settings.value.header.instagram"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    rows="5"
                                  ></b-form-input>
                                  <VValidate
                                    name="Instagram"
                                    v-model="content.web_settings.value.header.instagram"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <div>
                                    <p>{{ content.web_settings.value.header.instagram }}</p>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="w-100">
                              <hr />
                            </div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Tagline
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == 'header'">
                                  <b-form-input
                                    v-model="content.web_settings.value.footer.tagline"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    rows="5"
                                  ></b-form-input>
                                  <VValidate
                                    name="Tagline"
                                    v-model="content.web_settings.value.footer.tagline"
                                    rules="required"
                                  />
                                </template>
                                <template v-else>
                                  <div>
                                    <p>{{ content.web_settings.value.footer.tagline }}</p>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="moduleRole('Edit')">
                        <div class="col-12">
                          <hr />
                          <div class="text-right">
                            <template v-if="editFormId == 'header'">
                              <button
                                type="button"
                                @click="editFormId = ''"
                                class="btn btn-rounded btn-light mr-2"
                              >
                                Cancel
                              </button>
                              <button type="submit" class="btn btn-rounded btn-primary">
                                Save
                              </button>
                            </template>
                            <template v-else>
                              <button
                                type="button"
                                @click="editFormId = 'header'"
                                class="btn btn-rounded btn-primary"
                              >
                                Ubah
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

// import BoCard from '@/components/BoCard.vue'
// import BoCardImg from '@/components/BoCardImg.vue'

// import Gen from '@libs/Gen.js';

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    // BoCard,
    // BoCardImg
  },
  data() {
    return {
      contactInformation: {
        mapsUrl: '',
        address: '',
        email: '',
        instagram: '',
      },
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      idKey: "contact_id",
      statusKey: "contact_status",
      editor: ClassicEditor,
      contactDetail: {},
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },
      editFormId: "",
      mrStatus: [],
      content: {
        web_settings: {
          slug: '',
          value: {
            header: {
              maps_url: '',
              address: '',
              email: '',
              instagram: '',
            },
            footer: {
              tagline: '',
            },
          }
        },
        web_registration: {
          slug: '',
          value: {
            event: {
              url: '',
              open: '',
            },
            expo: {
              url: '',
              open: '',
            },
          },
        },
      },
      inputHeroImage: {}
    };
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue,
        };
        this.doFilter();
      },
    },
  },
  methods: {
    showModalDetail(v) {
      this.contactDetail = v;
      this.$bvModal.show('modalDetail');

      const { contact_id, contact_status } = v;

      if (contact_status === 'U') {
        const { data } = this.data;
        const index = data.findIndex((c) => c.contact_id === contact_id);

        if (index !== -1) {
          this.setContactStatus(contact_id, (type) => {
            if (type === 'success') {
              this.$set(this.data.data, index, {
                ...data[index],
                contact_status: 'R',
              });
            }
          });
        }
      }
    },

    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>